// scss
import "../scss/app.scss";
import "../scss/frontend/layout/cookieconsent.scss";
// ts, js
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/tab";
import {Popup} from "./common/Popup";

declare var window: any;
declare var app: any;

// js
const $ = require('jquery');

// make jQuery globally available
// @ts-ignore
global.$ = global.jQuery = $;

// @ts-ignore
global.app = global.app || {};

// @ts-ignore
export class AppModule {
}

window.Popup = Popup;
// bootstrap submenu show on click
$(".dropdown-submenu > a").on("click", function (e) {
    const submenu = $(this);
    // $(".dropdown-submenu .dropdown-menu").removeClass("show");
    if (submenu.next(".dropdown-menu").hasClass("show")) {
        submenu.next(".dropdown-menu").removeClass("show");
    } else {
        submenu.next(".dropdown-menu").addClass("show");
    }
    e.stopPropagation();
});

// hide any open menus when parent closes
$(".dropdown").on("hidden.bs.dropdown", () => {
    $(".dropdown-menu.show").removeClass("show");
});

const renderMessage = (message: string) => {
    return `
        <div class="alert alert-error alert-dismissible show --js-alert" role="alert">
            <span class="alert-message">${message}</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>`;
}

export const renderError = (messages) => {
    if (!messages) {
        return;
    }
    if (typeof messages === "string") {
        messages = [messages];
    }
    if (!(Array.isArray(messages) && messages.length > 0)) {
        return;
    }
    const elm = document.querySelector(".--js-sticky-top");
    for (const message of messages) {
        elm.innerHTML = elm.innerHTML + renderMessage(message);
    }
}

window.renderError = renderError;
