declare var window: any;
declare var document: any;

export class Popup {

    private windowPopup;
    private readonly url: string;
    private readonly title: string;
    private readonly width: number;
    private readonly height: number;
    private readonly options: string;

    constructor(url: string, title: string, width: number, height: number, options: string) {
        this.url = url;
        this.title = title;
        this.width = width;
        this.height = height;
        this.options = options;
    }

    public open(): this {
        this.windowPopup = window.open(
            this.url,
            this.title,
            this.options + " , width=" + this.width + ", height=" + this.height);
        return this;
    }

    public close(): void {
        if (this.windowPopup && !this.windowPopup.closed) {
            this.windowPopup.close();
        }
    }

    public center(): this {
        const pos = this.calculateCenterPosition(this.width, this.height);
        this.windowPopup.moveTo(pos.x, pos.y);
        this.focus();
        return this;
    }

    public focus(): void {
        if (window.focus) {
            this.windowPopup.focus();
        }
    }

    protected calculateCenterPosition(thisWidth: number, thisHeight: number): { x: number, y: number } {
        const multiDisplayScreenPositionLeft = window.screenLeft !== undefined ? window.screenLeft : window.Screen.left;
        const multiDisplayScreenPositionTop = window.screenLeft !== undefined ? window.screenTop : window.Screen.top;

        const width: number = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth
            ? document.documentElement.clientWidth : window.Screen.width;
        const height: number = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight
            ? document.documentElement.clientHeight : window.Screen.height;

        const left: number = ((width / 2) - (thisWidth / 2)) + multiDisplayScreenPositionLeft;
        const top: number = ((height / 2) - (thisHeight / 2)) + multiDisplayScreenPositionTop;

        return {x: left, y: top};
    }
}
